import { graphqlRequest, InstitutionQueryResult, useLocale } from '@modules';
import { CountryFromApi, Image } from '@types';
import { useEffect, useMemo, useState } from 'react';
import { getHistoryRankingQuery } from '../utils/graphql';

type Ranking = {
  id: number;
  name: string;
  rank: number;
  total: number;
  logo: Image | undefined;
  country?: CountryFromApi & { name?: string };
  rankTrend?: number;
  totalTrend?: number;
};

export type HistoryRanking = {
  current: Ranking;
  compared: Ranking;
};

export function useHistoryRankingData(fsType?: string, date?: string, compareDate?: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<InstitutionQueryResult | null>(null);
  const locale = useLocale();

  const fetchData = async () => {
    if (!fsType || !date || !compareDate) {
      return;
    }
    setIsLoading(true);
    const query = getHistoryRankingQuery(fsType, date, compareDate);

    try {
      const response = await graphqlRequest<InstitutionQueryResult>(query);
      setData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fsType, date, compareDate]);

  const ranking: HistoryRanking[] = useMemo(
    () =>
      data?.currentPeriod[0]?.institutions?.reduce<HistoryRanking[]>((acc, institution) => {
        const comparedInstitution = data?.comparePeriod[0]?.institutions?.find(
          (i) => i.name === institution.name && i.country.countryCode === institution.country.countryCode
        );

        if (!institution.country?.countryCode) return acc;

        const historyRanking: HistoryRanking = {
          current: {
            id: institution.id,
            name: institution.name,
            rank: institution.rank,
            total: institution.total,
            logo: institution.logo,
            country: {
              ...institution.country,
              name: data?.translations.find((t) => t.key === institution.country.countryCode)?.[locale],
            },
            totalTrend: comparedInstitution?.total ? institution.total - comparedInstitution.total : undefined,
            rankTrend: comparedInstitution?.rank ? comparedInstitution.rank - institution.rank : undefined,
          },
          compared: {
            id: comparedInstitution?.id ?? 0,
            name: comparedInstitution?.name ?? '',
            rank: comparedInstitution?.rank ?? 0,
            total: comparedInstitution?.total ?? 0,
            logo: comparedInstitution?.logo,
            country: comparedInstitution?.country && {
              ...comparedInstitution.country,
              name: data?.translations.find((t) => t.key === comparedInstitution.country.countryCode)?.[locale],
            },
          },
        };

        return [...acc, historyRanking];
      }, []) ?? [],
    [data, locale]
  );

  const sortedRanking = useMemo(() => {
    return ranking?.sort((a, b) => a.current.rank - b.current.rank);
  }, [ranking]);

  return { data: sortedRanking, isLoading };
}
