import styled from '@emotion/styled';
import { Box } from '@themed';
import { FsType, FsTypeBase } from '@types';
import React from 'react';
import { ChartWizardStep } from '../../shared/components/base';
import { useHistoryRankingData } from '../hooks';
import { useTrendFilter } from '../hooks/useTrendFilter';
import { FilterControls } from './FilterControl';
import { styles } from './RankingTable.styles';
import { TableRow } from './TableBody';
import { TableHeader } from './TableHeader';

const Table = styled.table({
  borderCollapse: 'collapse',
});

type RankingTableProps = {
  fsType: FsTypeBase<FsType>;
  date: string;
  compareDate: string;
};

export function RankingTable({ fsType, date, compareDate }: RankingTableProps) {
  const { data: historyRanking, isLoading } = useHistoryRankingData(fsType.text, date, compareDate);
  const {
    data: filteredHistoryRanking,
    totalInputValue,
    rankInputValue,
    setTotalInputValue,
    setRankInputValue,
    applyFilters,
    clearFilters,
  } = useTrendFilter(historyRanking);

  return (
    <ChartWizardStep id='chart' label='Ranking Table' isLoading={isLoading}>
      <FilterControls
        totalValue={totalInputValue}
        rankValue={rankInputValue}
        onTotalChange={setTotalInputValue}
        onRankChange={setRankInputValue}
        onApply={applyFilters}
        onClear={clearFilters}
      />
      {filteredHistoryRanking && (
        <Box id='chart' sx={styles.table}>
          <Table>
            <TableHeader fsType={fsType} date={date} compareDate={compareDate} />
            <tbody>
              {filteredHistoryRanking.map((item) => (
                <TableRow key={item.current.id} current={item.current} compared={item.compared} />
              ))}
            </tbody>
          </Table>
        </Box>
      )}
    </ChartWizardStep>
  );
}
