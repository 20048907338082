import { Flex, Image, SxStyleProp, Text, themeConfig } from '@themed';
import React, { memo } from 'react';
import { TrendComponent } from 'src/components/composite/finnoscore-list/Trend';
import { getTrend } from '../utils/trend';
import { styles } from './RankingTable.styles';

const TableBodyText = memo(({ children, sx }: { children: React.ReactNode; sx?: SxStyleProp }) => (
  <td>
    <Text sx={{ ...styles.tbodyText, ...sx }}>{children}</Text>
  </td>
));
TableBodyText.displayName = 'TableBodyText';

export const TableRow = memo(({ current, compared }: { current: any; compared: any }) => (
  <tr style={{ borderBottom: `1px solid ${themeConfig.colors.mutedDark}` }}>
    <TableBodyText>{current.rank}</TableBodyText>
    <td>
      <Flex sx={{ alignItems: 'center' }}>
        {current?.logo ? <Image src={current.logo?.url} alt={current.logo?.alternativeText} sx={styles.logo} /> : <span>&nbsp;</span>}
        <Text sx={styles.name}>{current.name}</Text>
      </Flex>
    </td>
    <TableBodyText sx={{ fontSize: '0.875rem' }}>{current.country?.name}</TableBodyText>
    <td>
      <Flex variant='flex.center'>
        <Text sx={styles.tbodyText}>{current.total.toFixed(2)}</Text>
        <TrendComponent sx={styles.trendIcon} trend={getTrend(current.totalTrend)} />
      </Flex>
    </td>
    <TableBodyText>{compared.total !== 0 ? compared.total?.toFixed(2) : '-'}</TableBodyText>
    <TableBodyText sx={styles.trend(current.totalTrend)}>{current.totalTrend?.toFixed(2)}</TableBodyText>
    <td>
      <Flex variant='flex.center'>
        <Text sx={styles.tbodyText}>{current.rank}</Text>
        <TrendComponent sx={styles.trendIcon} trend={getTrend(current.rankTrend)} />
      </Flex>
    </td>
    <TableBodyText>{compared.rank !== 0 ? compared.rank : '-'}</TableBodyText>
    <TableBodyText sx={styles.trend(current.rankTrend)}>{current.rankTrend}</TableBodyText>
  </tr>
));
TableRow.displayName = 'TableRow';
