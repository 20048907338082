import { SectionWithContent } from '@core';
import { Grid } from '@themed';
import React from 'react';
import { ChartDropDownHeader, ChartWizardStep, finnoChartStyles } from '../shared/components/base';
import { ChartDropDownControlComponent } from '../shared/components/control';
import { usePeriodDropDownControl, useTypeDropDownControl } from '../shared/hooks';
import { RankingTable } from './components/RankingTable';

export const HistoryRankingChart = () => {
  const { types, setTypeId, typeId, type: fsType, isLoading: isTypeLoading } = useTypeDropDownControl();
  const {
    periods: relativePeriodList,
    period: relativePeriod,
    periodId: relativePeriodId,
    setPeriodId: setRelativePeriodId,
    isLoading: isRelativePeriodLoading,
  } = usePeriodDropDownControl(fsType);

  const {
    periods: currentPeriodList,
    period: currentPeriod,
    periodId: currentPeriodId,
    setPeriodId: setcurrentPeriodId,
  } = usePeriodDropDownControl(fsType);

  const shouldRenderTable = fsType && relativePeriod?.date && currentPeriod?.date;

  return (
    <SectionWithContent sx={finnoChartStyles.container}>
      <Grid sx={finnoChartStyles.content}>
        <ChartWizardStep id='type' label='Type' isLoading={isTypeLoading}>
          <ChartDropDownControlComponent zIndex={20} list={types} selectedId={typeId} setter={setTypeId}>
            <ChartDropDownHeader title={fsType?.text || 'Choose a type'} />
          </ChartDropDownControlComponent>
        </ChartWizardStep>

        {/* The current period, but the ID is pastPeriod—just to position it first in the order. */}
        {fsType && (
          <ChartWizardStep id='pastPeriod' label='Current period' isLoading={isRelativePeriodLoading} loading={null}>
            <ChartDropDownControlComponent zIndex={10} list={currentPeriodList} selectedId={currentPeriodId} setter={setcurrentPeriodId}>
              <ChartDropDownHeader title={currentPeriod?.text || 'Choose a period'}></ChartDropDownHeader>
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {fsType && (
          <ChartWizardStep id='currentPeriod' label='Relative period' isLoading={isRelativePeriodLoading}>
            <ChartDropDownControlComponent zIndex={15} list={relativePeriodList} selectedId={relativePeriodId} setter={setRelativePeriodId}>
              <ChartDropDownHeader title={relativePeriod?.text || 'Choose a period'}></ChartDropDownHeader>
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {shouldRenderTable && <RankingTable fsType={fsType!} date={currentPeriod!.date} compareDate={relativePeriod!.date} />}
      </Grid>
    </SectionWithContent>
  );
};
