import { getTranslationsQuery } from 'src/modules/networking/queries';

export function getHistoryRankingQuery(fsType: string, date: string, compareDate: string) {
  return `{
    currentPeriod: fsPeriods(
      sort: "date:desc"
      publicationState: PREVIEW
      where: { fsType: "${fsType}", date_lte: "${date}" }
      limit: 1
    ) {
      id
      date
      institutions: fs_institutions(where: { fsType: "${fsType}" }) {
        id
        fsType
        name
        logo {
          url
          alternativeText
        }
        total
        rank
        country: fs_country {
          countryCode
          flag {
            url
            alternativeText
          }
        }
      }
    }
    
    comparePeriod: fsPeriods(
      sort: "date:desc"
      publicationState: PREVIEW
      where: { fsType: "${fsType}", date_lte: "${compareDate}" }
      limit: 1
    ) {
      id
      date
      institutions: fs_institutions(where: { fsType: "${fsType}" }) {
        id
        fsType
        name
        logo {
          url
          alternativeText
        }
        total
        rank
        country: fs_country {
          countryCode
          flag {
            url
            alternativeText
          }
        }
      }
    }
    ${getTranslationsQuery()}
  }`;
}
